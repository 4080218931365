<template>
  <!--begin::Employee-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder text-dark">{{ employee.name.display }}
          <span class="d-block text-muted font-weight-lighter pt-2 font-size-sm">{{ $t("MANAGEMENT.EMPLOYEE_PROFILE") }}</span>
        </h3>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body my-10">
      <div class="row">
        <label class="col-xl-3"></label>
        <div class="col-lg-9 col-xl-6">
          <h5 class="font-weight-bolder mb-6">{{ $t("MANAGEMENT.PERSONAL_DATA") }}</h5>
        </div>
      </div>
      <div class="form-group row align-items-center mb-0">
        <label class="col-xl-3 col-lg-3 col-form-label font-weight-bolder text-right">ID:</label>
        <div class="col-lg-9 col-xl-6">{{ employee.id }}</div>
      </div>
      <div class="form-group row align-items-center mb-0">
        <label class="col-xl-3 col-lg-3 col-form-label font-weight-bolder text-right">{{ $t("MANAGEMENT.LAST_NAME_TITLE") }}</label>
        <div class="col-lg-9 col-xl-6">{{ employee.name.last }}</div>
      </div>
      <div class="form-group row align-items-center mb-0">
        <label class="col-xl-3 col-lg-3 col-form-label font-weight-bolder text-right">{{ $t("MANAGEMENT.FIRST_NAME_TITLE") }}</label>
        <div class="col-lg-9 col-xl-6">{{ employee.name.first }}</div>
      </div>
      <div class="form-group row align-items-center mb-0">
        <label class="col-xl-3 col-lg-3 col-form-label font-weight-bolder text-right">{{ $t("MANAGEMENT.PATRONYMIC_TITLE") }}</label>
        <div class="col-lg-9 col-xl-6">{{ employee.name.patronymic }}</div>
      </div>
      <div class="form-group row align-items-center mb-0">
        <label class="col-xl-3 col-lg-3 col-form-label font-weight-bolder text-right">{{ $t("MANAGEMENT.JOB_TITLE") }}</label>
        <div class="col-lg-9 col-xl-6">{{ employee.profile ? employee.profile.job_title : '' }}</div>
      </div>
      <div class="form-group row align-items-center mb-0">
        <label class="col-xl-3 col-lg-3 col-form-label font-weight-bolder text-right">Email:</label>
        <div class="col-lg-9 col-xl-6">{{ employee.email }}</div>
      </div>
      <div class="form-group row align-items-center mb-0">
        <label class="col-xl-3 col-lg-3 col-form-label font-weight-bolder text-right">{{ $t("MANAGEMENT.PHONE_NUMBER_TITLE") }}</label>
        <div class="col-lg-9 col-xl-6">{{ employee.profile ? employee.profile.phone : '' }}</div>
      </div>
      <div class="form-group row align-items-center mb-0">
        <label class="col-xl-3 col-lg-3 col-form-label font-weight-bolder text-right">{{ $t("MANAGEMENT.MASTER_SYSTEM") }}</label>
        <div class="col-lg-9 col-xl-6"></div>
      </div>
      <div class="row mt-10">
        <label class="col-xl-3"></label>
        <div class="col-lg-9 col-xl-6">
          <h5 class="font-weight-bolder mb-6">{{ $t("MANAGEMENT.GROUPS_TITLE") }}</h5>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label font-weight-bolder text-right">{{ $t("MANAGEMENT.EMPLOYEE_GROUPS") }}</label>
        <div class="col-lg-9 col-xl-6">
          <span v-for="(group, i) in employee.groups" :key="i" class="label label-lg label-inline m-1">{{ group.name }}</span>
        </div>
      </div>
      <div class="row mt-10">
        <label class="col-xl-3"></label>
        <div class="col-lg-9 col-xl-6">
          <h5 class="font-weight-bolder mb-6">{{ $t("MANAGEMENT.ROLES_TITLE") }}</h5>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label font-weight-bolder text-right">{{ $t("MANAGEMENT.EMPLOYEE_ROLES") }}</label>
        <div class="col-lg-9 col-xl-6">
          <span v-for="(role, i) in employee.roles" :key="i" class="label label-lg label-inline m-1">{{ role.name }}</span>
        </div>
      </div>
      <div class="row mt-10">
        <label class="col-xl-3"></label>
        <div class="col-lg-9 col-xl-6">
          <h5 class="font-weight-bolder mb-6">{{ $t("MANAGEMENT.ACS_TITLE") }}</h5>
        </div>
      </div>
      <div class="form-group row align-items-center mb-0">
        <label class="col-xl-3 col-lg-3 col-form-label font-weight-bolder text-right">{{ $t("MANAGEMENT.ACS_CARD_NUMBER_TITLE") }}</label>
        <div class="col-lg-9 col-xl-6">
          <span class="d-inline-block pt-3">{{ !employee.card ? $t("MANAGEMENT.NOT_ASSIGNED_TITLE") : employee.card }}</span>
<!--          <b-button class="btn btn-icon btn-light btn-hover-primary btn-sm ml-5"-->
<!--                    v-b-modal.modal-add-edit-->
<!--                    @click="onAddEditNumber(employee)"-->
<!--                    v-b-tooltip.hover.v-dark="'Редактировать'">-->
<!--            <Settings/>-->
<!--          </b-button>-->
          <b-button class="btn btn-button btn-light btn-sm ml-5"
                  v-b-modal.modal-add-edit
                  @click="onAddEditNumber(employee)">
            <span class="svg-icon svg-icon-dark-50"><Settings/></span>
            <span class="font-weight-bolder">{{ $t("MANAGEMENT.EDIT_BUTTON") }}</span>
          </b-button>
        </div>
      </div>
    </div>
    <!--end::Body-->
    <!--begin::Add Edit Modal-->
    <b-modal
        id="modal-add-edit"
        centered
        @hidden="resetModal"
    >
      <template #modal-header="{ close }">
        <div class="w-100">
          <h5 class="float-left font-weight-bolder text-dark">{{ $t("MANAGEMENT.EMPLOYEE_ACS_CARD_TITLE") }}</h5>
          <span @click="close()" class="float-right cursor-pointer">
            <span class="font-size-h5" aria-hidden="true">&times;</span>
          </span>
        </div>
      </template>
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
            label-for="name-input"
            :invalid-feedback="$t('MANAGEMENT.ACS_CARD_INPUT_INVALID_FEEDBACK')"
            :state="numberState"
        >
          <template slot="label">{{ $t("MANAGEMENT.ACS_CARD_INPUT_TITLE") }} <span class="font-weight-bolder text-danger">*</span></template>
          <b-form-input
              id="name-input"
              :placeholder="$t('MANAGEMENT.ACS_CARD_INPUT_PLACEHOLDER')"
              v-model="form.card"
              :state="numberState"
              required
          ></b-form-input>
        </b-form-group>
      </form>
      <div class="row">
        <div class="col-md-12 alert alert-custom alert-light fade show" role="alert">
          <div class="alert-icon"><Clipboard/></div>
          <div class="alert-text text-secondary">{{ $t("MANAGEMENT.ACS_CARD_INPUT_DESC") }}</div>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="light-primary"
              class="float-left font-weight-bolder"
              @click="resetModal">
            {{ $t("MANAGEMENT.CANCEL_BUTTON") }}
          </b-button>
          <p class="float-right">
            <b-button
                class="font-weight-bolder"
                variant="primary"
                @click="handleSave">
              {{ $t("MANAGEMENT.SAVE_BUTTON") }}
            </b-button>
          </p>
        </div>
      </template>
    </b-modal>
    <!--begin::Add Edit Modal-->
  </div>
  <!--end::Employee-->
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_EMPLOYEE, POST_EMPLOYEE_CARD } from "@/core/services/store/management.module";

import Settings from "@/view/layout/svg/Settings";
import Clipboard from "@/view/layout/svg/Clipboard";

export default {
  name: "Employee",
  components: {
    Settings,
    Clipboard
  },
  mounted() {
    this.$store.dispatch(GET_EMPLOYEE, this.$route.params.id).then(() => {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t("MANAGEMENT.EMPLOYEES"), route: "/management/employees" },
        { title: `${this.employee.name.display}` }
      ]);
    });
  },
  data() {
    return {
      form: {},
      numberState: null,
    }
  },
  computed: {
    ...mapGetters([
        "employee"
    ])
  },
  methods: {
    onAddEditNumber(item) {
      this.form = item;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.numberState = valid;
      return valid;
    },
    handleSave(event) {
      event.preventDefault();
      this.handleSubmit();
    },
    resetModal() {
      this.form = {};
      this.$store.dispatch(GET_EMPLOYEE, this.$route.params.id);
      this.$bvModal.hide('modal-add-edit');
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.$store.dispatch(POST_EMPLOYEE_CARD, { id: this.form.id, code: { code: this.form.card } })
          .then(() => this.resetModal() )
    }
  }
}
</script>

<style scoped>

</style>
